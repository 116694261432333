import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const listsAPIService = createApi({
  reducerPath: "listsAPIService",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://jsonplaceholder.typicode.com/",
  }),
  endpoints: (builder) => ({
    // posts
    getPosts: builder.query({ query: () => "posts" }),
    getPostById: builder.query({
      query: (postId) => `posts/${postId}`,
    }),
    getPostsCommentsById: builder.query({
      query: (postId) => `posts/${postId}/comments`,
    }),
    // comments
    getComments: builder.query({ query: () => "comments" }),
    getCommentById: builder.query({
      query: (postId) => `comments/${postId}`,
    }),
    getAlbums: builder.query({ query: () => "albums" }),
    getPhotos: builder.query({ query: () => "photos" }),
    getTodos: builder.query({ query: () => "todos" }),
    getUsers: builder.query({ query: () => "users" }),
  }),
});

export const {
  useGetPostsQuery,
  useGetPostByIdQuery,
  useGetPostsCommentsByIdQuery,
  useGetCommentsQuery,
  useGetCommentByIdQuery,
  useGetAlbumsQuery,
  useGetPhotosQuery,
  useGetTodosQuery,
  useGetUsersQuery,
} = listsAPIService;
