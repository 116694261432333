import { useState } from "react";
import {
  providencesList,
  districtsList,
  muncipalsList,
} from "../../utils/addresses";
/* permanent address: street, muncipality, district, providence, country */
const AddressInfo = () => {
  const [info, setInfo] = useState({
    country: "NP",
    providence: 0,
    district: 0,
    muncipality: 0,
    street: 0,
  });
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="flex gap3">
        <select
          placeholder="Country"
          value={info.country}
          onChange={(e) => setInfo({ ...info, country: e.target.value })}
        >
          <option value="" disabled>
            Select Country
          </option>
          <option value="NP">Nepal</option>
          <option value="IN">India</option>
        </select>
        <select
          value={info.providence}
          onChange={(e) => setInfo({ ...info, providence: e.target.value })}
        >
          <option value="" disabled>
            Select Province
          </option>

          {providencesList.map((p) => (
            <option key={p.id} value={p.id}>
              {p.name}
            </option>
          ))}
        </select>
        <select
          placeholder="District"
          value={info.district}
          onChange={(e) => setInfo({ ...info, district: e.target.value })}
        >
          <option value="" disabled>
            Select District
          </option>
          {districtsList.map((p) => (
            <option key={p.id} value={p.id}>
              {p.name}
            </option>
          ))}
        </select>
        <select
          placeholder="Muncipality"
          value={info.muncipality}
          onChange={(e) => setInfo({ ...info, muncipality: e.target.value })}
        >
          <option value="" disabled>
            Select Muncipal
          </option>
          {muncipalsList.map((p) => (
            <option key={p.id} value={p.id}>
              {p.name}
            </option>
          ))}
        </select>
        <input type="text" placeholder="Address" />
      </div>
      <button type="submit">Submit</button>
    </form>
  );
};

export default AddressInfo;
