import { createSlice } from "@reduxjs/toolkit";

const globalSlice = createSlice({
  name: "global",
  initialState: {
    authPopup: false,
    showToast: false,
    toastMessage: "This is toast message!",
    toastType: 2,
  },
  reducers: {
    updateAuthPopup: (state, action) => {
      state.authPopup = action.payload;
    },
    updateToast: (state, action) => {
      state.showToast = action.payload.toast;
      state.toastMessage = action.payload.message;
      state.toastType = action.payload.type;
    },
  },
});

export const { updateAuthPopup, updateToast } = globalSlice.actions;
export default globalSlice.reducer;
