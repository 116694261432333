import Header from "./components/app/header";
import Footer from "./components/app/footer";
import BottomAppbar from "./components/app/bottom-appbar";
import { Outlet } from "react-router-dom";
import Loader from "./components/loader";
import { Suspense, useEffect } from "react";
import AuthPopup from "./components/auth/auth-popup";
// import ForgotPassword from "./components/auth/forgot-password";
import { useSelector } from "react-redux";
import { toastReducer, authPopupReducer } from "./redux/reducers";
import ToastComponent from "./components/toast-component";
const App = () => {
  const toast = useSelector(toastReducer);
  const authPopup = useSelector(authPopupReducer);
  useEffect(() => {
    const header = document.querySelector("header");
    window.addEventListener("scroll", () => {
      if (window.screenY > 0) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }
    });
  }, []);

  return (
    <>
      <Suspense fallback={<Loader />}>
        <ToastComponent />
        <div className="app flex flex-col align-center justify-center">
          <Header />
          <section>
            <Outlet />
          </section>
          <Footer />
          <BottomAppbar />
        </div>
      </Suspense>
      {authPopup && <AuthPopup />}
      {/* {<ForgotPassword />} */}
      {toast && <ToastComponent />}
    </>
  );
};

export default App;
