import { NavLink } from "react-router-dom";
import { Menu, X } from "lucide-react";
import { menuItems, profileMenuItems } from "../../utils/constants";
import { useState, useEffect, useRef } from "react";

const Header = () => {
  const ref1 = useRef();
  const ref2 = useRef();
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  const [toggleMenu, setToggleMenu] = useState(false);

  useEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    const handleClick = (e) => {
      // if (e.target == ref1.current || e.target === ref2.current) {
      //   setToggleMenu(!toggleMenu);
      // } else {
      //   setToggleMenu(false);
      // }
    };
    window.addEventListener("click", handleClick);
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("click", handleClick);
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  return (
    <header className="flex pos-sticky">
      <div className="menu__content flex align-center px4">
        <div className="logo" style={{ flex: 1 }}>
          Study2ool
        </div>
        <div className="menu__list flex">
          {menuItems.map((item, i) => (
            <NavLink
              key={i}
              to={item.path}
              className="flex align-center justify-center px4"
            >
              {item.title}
            </NavLink>
          ))}
        </div>
        <button
          ref={ref1}
          className="link square"
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          {toggleMenu ? <X ref={ref2} /> : <Menu ref={ref2} />}
        </button>
        {toggleMenu && (
          <div className="mini__menu">
            {(size[0] <= 600 ? menuItems : profileMenuItems).map((item, i) => (
              <NavLink
                key={i}
                to={item.path}
                className="flex align-center justify-center px4"
                onClick={(e) => setToggleMenu(false)}
              >
                {item.icon}
                <span>{item.title}</span>
              </NavLink>
            ))}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
