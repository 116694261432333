import { configureStore } from "@reduxjs/toolkit";
import globalSlice from "./global-slice";
import listsSlice from "./lists-slice";
import { listsAPIService } from "../services/lists-service";

const store = configureStore({
  reducer: {
    global: globalSlice,
    lists: listsSlice,
    [listsAPIService.reducerPath]: listsAPIService.reducer,
  },
  middleware: (middleware) =>
    middleware({ serializableCheck: false }).concat(listsAPIService.middleware),
});

export default store;
