import { useGetPostsQuery } from "../services/lists-service";
import TestimonialList from "../components/testimonial-list";
import PostsList from "../components/posts-list";
import { useDispatch } from "react-redux";
import { updateAuthPopup } from "../redux/global-slice";

const Home = () => {
  const dispatch = useDispatch();
  const { isLoading, data, error } = useGetPostsQuery();

  return (
    <div className="body__container">
      {/* <button
        onClick={() =>
          dispatch(
            updateToast({
              toast: true,
              message:
                "React Hook useEffect has a missing dependency: 'dispatch'. Either include it or remove the dependency array",
              type: 2,
            })
          )
        }
      >
        Click Me
      </button> */}
      <div
        className="flex align-center justify-center radius2"
        style={{
          height: "50vh",
          backgroundColor: "var(--primary-color)",
          color: "white",
          fontSize: "3rem",
        }}
      >
        <button onClick={() => dispatch(updateAuthPopup(true))}>
          Click Me
        </button>
      </div>

      <TestimonialList />

      <PostsList posts={data} isLoading={isLoading} error={error} />
    </div>
  );
};

export default Home;
